import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Checkbox, Paper, makeStyles } from '@material-ui/core';
import { Cancel, CheckCircle, MoreHoriz, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import { TBACurrentSearchNoTransSplashScreen } from './TBACurrentSearchNoTransSplashScreen';
import { TbaUncontrolledTransactionsHeader } from './TbaUncontrolledTransactionHeader';
import { CenteredProgress, CustomIconMenu, DropDownButton, SearchAndSort, SortOrder } from '../../../components';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useRangeTypes } from '../../../hooks/useRangeTypes';
import { fetchEntities } from '../../../redux/entities';
import {
  bulkDeleteTBAUncontrolledTransaction,
  bulkUpdateUncontrolledTransactionComparabilityAcceptance,
  deassociateTBAUncontrolledTransaction,
  fetchCurrentTBA,
  fetchTbaRangeResults,
  fetchTbaRangeTypes,
  fetchUncontrolledTransactionParameters,
  updateUncontrolledTransactionComparabilityAcceptance
} from '../../../redux/transactionBasedAnalyses';
import { selectEntitiesList, selectWorkingContainer } from '../../../selectors';
import { filterData, sortData } from '../../../services/filtering';
import { AppDispatch } from '../../../store';
import tokens from '../../../styles/designTokens';
import { body1, body2 } from '../../../styles/typography';
import { SelectedUndetermined } from '../../../svgs';
import { formatRangeValue, getRangeValueHeader } from '../../../utils';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { SingleChipProps } from '../../SummaryChips';
import { Table } from '../../Table';
import { StickyColsDefinition } from '../../Table/Table.proptype';
import { statusIdMap } from '../../TbaComparableAnalysis/TbaComparableAnalysis.proptype';
import {
  IconMenuItem,
  UncontrolledTransactionComparabilities,
  CurrentSearchUncontrolledTransaction,
  StatusTypes,
  TbaUncontrolledTransSearchTableProps
} from '../TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    minHeight: '14rem',
    padding: '1.375em',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto'
  },
  removeTbaUncontrolledTransactionIcon: {
    color: tokens.product100,
    paddingTop: '0.375rem',
    paddingLeft: '0.375rem',
    borderRadius: '2rem',
    width: '2.25rem',
    height: '2.25rem',
    '&:hover': {
      cursor: 'pointer',
      background: tokens.product25
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&.MuiTableCell-head': {
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      },
      '&.MuiTableCell-body': {
        padding: '0px 15px',
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      }
    }
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  checkCircle: {
    color: tokens.positive70
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  },
  rowSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.product15
      }
    },
    '& .MuiTableCell-root': {
      backgroundColor: tokens.neutral70
    },
    '& td.MuiTableCell-body:first-child': {
      color: tokens.product100,
      borderLeft: `0.188rem solid ${tokens.purpleLight2}`,
      ...body2,
      width: '10rem'
    },
    '& td.MuiTableCell-body:nth-child(2)': {
      ...body2
    }
  },
  rowNotSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.neutral60
      }
    },
    '& td.MuiTableCell-body:first-child': {
      ...body2,
      width: '10rem'
    },
    '& td.MuiTableCell-body:nth-child(2)': {
      ...body2
    }
  }
}));

export const TbaUncontrolledTransSearchTable = ({
  tba,
  tabLabel,
  onCreateSingleTransaction,
  onUploadMultipleTransactions,
  setIsOpenImportFromRoyaltyStatModal,
  onCopyTba,
  onEditSingleTransaction,
  currentSearchUncontrolledTransactions,
  savingNewTransaction,
  isUnitPrice
}: TbaUncontrolledTransSearchTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [handleRangeTypes] = useRangeTypes(tba.tbaId);
  const entities = useSelector(selectEntitiesList);
  const workingContainer = useSelector(selectWorkingContainer);
  const [statusToDisplay, setStatusToDisplay] = useState<StatusTypes>('total');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [allUncontrolledSelected, setAllUncontrolledSelected] = useState(false);
  const [featureFlagIsActive] = useFeatureFlags();

  const transactionType = tba?.transactionType;
  const method = tba?.tbaEvaluationMethod.name;

  const showRangeValueFixFormatting = featureFlagIsActive('tbaRangeValuesFormatting');

  useEffect(() => {
    if (!entities) {
      void dispatch(fetchEntities());
    }
  }, [entities, dispatch]);

  useEffect(() => {
    if (selectedIds.length > 0 && selectedIds.length === currentSearchUncontrolledTransactions?.length) {
      setAllUncontrolledSelected(!allUncontrolledSelected);
    } else {
      setAllUncontrolledSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchUncontrolledTransactions?.length, selectedIds]);

  const handleSelectUncontrolledTransaction = (uncontrolledTransactionId: number) => {
    const currentIds = [...selectedIds];
    if (currentIds.includes(uncontrolledTransactionId))
      currentIds.splice(currentIds.indexOf(uncontrolledTransactionId), 1);
    else currentIds.push(uncontrolledTransactionId);

    setSelectedIds(currentIds);
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'tba_button_click',
      event_label: `${tabLabel ?? ''} Bulk Edit click`,
      container_id: workingContainer?.containerId
    });
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setAllUncontrolledSelected(false);
  };

  const removeTbaUncontrolledTransaction = async (transactionId: number) => {
    void dispatch(deassociateTBAUncontrolledTransaction({ tbaId: tba.tbaId, transactionId })).then(() => {
      void dispatch(fetchCurrentTBA(tba.tbaId));
      void dispatch(fetchUncontrolledTransactionParameters(tba.tbaId));
      void dispatch(fetchTbaRangeTypes(tba.tbaId));
      void dispatch(fetchTbaRangeResults(tba.tbaId));
    });
  };

  const bulkRemoveTbaUncontrolledTransactions = async (transactionIds: number[]) => {
    void dispatch(
      bulkDeleteTBAUncontrolledTransaction({
        tbaId: tba.tbaId,
        transactionIds
      })
    ).then(() => {
      void dispatch(fetchCurrentTBA(tba.tbaId));
      void dispatch(fetchUncontrolledTransactionParameters(tba.tbaId));
      void dispatch(fetchTbaRangeTypes(tba.tbaId));
      void dispatch(fetchTbaRangeResults(tba.tbaId));

      setSelectedIds([]);
    });
  };

  const updateComparability = async (transactionComparabilityId: number, status: number) => {
    void dispatch(
      updateUncontrolledTransactionComparabilityAcceptance({
        transactionComparabilityId,
        status
      })
    )
      .then(handleRangeTypes)
      .then(() => {
        void dispatch(fetchTbaRangeTypes(tba.tbaId));
        void dispatch(fetchTbaRangeResults(tba.tbaId));
      });
  };

  const bulkUpdateComparability = async (uncontrolledTransactionIds: number[], status: number) => {
    void dispatch(
      bulkUpdateUncontrolledTransactionComparabilityAcceptance({
        uncontrolledTransactionIds,
        tbaId: tba.tbaId,
        status
      })
    )
      .then(handleRangeTypes)
      .then(() => {
        void dispatch(fetchTbaRangeTypes(tba.tbaId));
        void dispatch(fetchTbaRangeResults(tba.tbaId));

        setSelectedIds([]);
      });
  };

  const filterByStatus = (status: StatusTypes) => {
    setStatusToDisplay(status);
  };

  const selectAll = () => {
    if (allUncontrolledSelected) {
      handleDeselectAll();
    } else {
      setSelectedIds(currentSearchUncontrolledTransactions?.map(({ transactionId }) => transactionId) ?? []);
      /* eslint-disable camelcase */
      void logGoogleAnalyticsEvent({
        event_category: 'tba_button_click',
        event_label: `${tabLabel ?? ''} Select All Bulk Edit click`,
        container_id: workingContainer?.containerId
      });
    }
  };

  const chipsAmounts: number[] = [
    currentSearchUncontrolledTransactions?.length ?? 0,
    currentSearchUncontrolledTransactions
      ? currentSearchUncontrolledTransactions.filter(
          (currentSearchUncontrolledTransaction) =>
            currentSearchUncontrolledTransaction.status === UncontrolledTransactionComparabilities.Rejected
        ).length
      : 0,
    currentSearchUncontrolledTransactions
      ? currentSearchUncontrolledTransactions.filter(
          (currentSearchUncontrolledTransaction) =>
            currentSearchUncontrolledTransaction.status === UncontrolledTransactionComparabilities.Accepted
        ).length
      : 0
  ];

  const chipsData: SingleChipProps[] = [
    {
      isSelected: statusToDisplay === 'total',
      label: t('analysis:comparable-range-total'),
      amount: chipsAmounts[0],
      key: 'total',
      disabled: chipsAmounts[0] === 0
    },
    {
      isSelected: statusToDisplay === 'rejected',
      label: t('analysis:comparable-range-rejected'),
      amount: chipsAmounts[1],
      key: 'rejected',
      disabled: chipsAmounts[1] === 0
    },
    {
      isSelected: statusToDisplay === 'accepted',
      label: t('analysis:comparable-range-accepted'),
      amount: chipsAmounts[2],
      key: 'accepted',
      disabled: chipsAmounts[2] === 0
    }
  ];

  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};
  let tableDisplayData: CurrentSearchUncontrolledTransaction[] = [];

  if (currentSearchUncontrolledTransactions) {
    const uncontrolledTransactionsBySelectedStatus: CurrentSearchUncontrolledTransaction[] = currentSearchUncontrolledTransactions.filter(
      (aTransaction) => statusToDisplay === 'total' || aTransaction.status === statusIdMap[statusToDisplay]
    );

    const mapToTableData = uncontrolledTransactionsBySelectedStatus.map((currentSearchUncontrolledTransaction) => {
      const items: IconMenuItem[] = [
        {
          text: t('analysis:tba-uncontrolled-transactions-list-menu-option-edit-transaction'),
          handleOnClick: () => {
            onEditSingleTransaction(currentSearchUncontrolledTransaction.transactionId, tabLabel ?? '');
          }
        },
        {
          text: t('analysis:tba-uncontrolled-transactions-list-menu-option-remove-transaction'),
          handleOnClick: () => {
            void removeTbaUncontrolledTransaction(currentSearchUncontrolledTransaction.transactionId);
          }
        },
        {
          text: t('analysis:tba-uncontrolled-transactions-list-menu-option-delete-transaction'),
          handleOnClick: () => {
            console.log('Delete Transaction', currentSearchUncontrolledTransaction.transactionId);
          }
        }
      ];

      return currentSearchUncontrolledTransaction
        ? {
            transactionId: currentSearchUncontrolledTransaction.identifier,
            entityName: `${
              currentSearchUncontrolledTransaction.ucSourceLegalEntity?.name
                ? String(currentSearchUncontrolledTransaction.ucSourceLegalEntity?.name)
                : String(currentSearchUncontrolledTransaction.sourceLegalEntity?.name)
            } → ${
              currentSearchUncontrolledTransaction.ucDestinationLegalEntity?.name
                ? String(currentSearchUncontrolledTransaction.ucDestinationLegalEntity?.name)
                : String(currentSearchUncontrolledTransaction.destinationLegalEntity?.name)
            }`,
            transactionType: currentSearchUncontrolledTransaction.transactionType?.name,
            propertyTransferred: currentSearchUncontrolledTransaction.propertyTransferred,
            rangeValue:
              typeof currentSearchUncontrolledTransaction.parameterValue === 'number' &&
              !Number.isNaN(currentSearchUncontrolledTransaction.parameterValue)
                ? showRangeValueFixFormatting
                  ? formatRangeValue(currentSearchUncontrolledTransaction.parameterValue, isUnitPrice)
                  : currentSearchUncontrolledTransaction.parameterValue.toFixed(2)
                : 'N/A',
            acceptedStatus: (
              <DropDownButton
                variant="text"
                className={classes.statusDropDown}
                items={[
                  {
                    children: (
                      <>
                        <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                        <Typography className={`${classes.dropDown}`}>
                          {t(`analysis:comparable-range-accepted`)}
                        </Typography>
                      </>
                    ),
                    onClick: () => {
                      /* eslint-disable camelcase */
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `${tabLabel ?? ''} Accepted Status Update`,
                        container_id: workingContainer?.containerId
                      });
                      void updateComparability(
                        currentSearchUncontrolledTransaction.transactionComparabilityId ?? 0,
                        UncontrolledTransactionComparabilities.Accepted
                      );
                    }
                  },
                  {
                    children: (
                      <>
                        <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                        <Typography className={classes.dropDown}>{t(`analysis:comparable-range-rejected`)}</Typography>
                      </>
                    ),
                    onClick: () => {
                      /* eslint-disable camelcase */
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `${tabLabel ?? ''} Rejected Status Update`,
                        container_id: workingContainer?.containerId
                      });
                      void updateComparability(
                        currentSearchUncontrolledTransaction.transactionComparabilityId ?? 0,
                        UncontrolledTransactionComparabilities.Rejected
                      );
                    }
                  },
                  {
                    children: (
                      <>
                        <SelectedUndetermined className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                        <Typography className={classes.dropDown}>
                          {t(`analysis:comparable-range-undetermined`)}
                        </Typography>
                      </>
                    ),
                    onClick: () => {
                      /* eslint-disable camelcase */
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `${tabLabel ?? ''} Undetermined Status Update`,
                        container_id: workingContainer?.containerId
                      });
                      void updateComparability(
                        currentSearchUncontrolledTransaction.transactionComparabilityId ?? 0,
                        UncontrolledTransactionComparabilities.Undetermined
                      );
                    }
                  }
                ]}
                onItemClick={() => {
                  /* eslint-disable camelcase */
                  void logGoogleAnalyticsEvent({
                    event_category: 'tba_button_click',
                    event_label: `${tabLabel ?? ''} Accepted Status Cell click`,
                    container_id: workingContainer?.containerId
                  });
                }}
              >
                {currentSearchUncontrolledTransaction.status === UncontrolledTransactionComparabilities.Accepted && (
                  <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                )}
                {currentSearchUncontrolledTransaction.status === UncontrolledTransactionComparabilities.Rejected && (
                  <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                )}
                {currentSearchUncontrolledTransaction.status ===
                  UncontrolledTransactionComparabilities.Undetermined && (
                  <SelectedUndetermined className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                )}
              </DropDownButton>
            ),
            className: selectedIds.includes(currentSearchUncontrolledTransaction.transactionId)
              ? classes.rowSelected
              : classes.rowNotSelected,
            remove: (
              <Box
                className={classes.removeTbaUncontrolledTransactionIcon}
                onClick={() => {
                  void removeTbaUncontrolledTransaction(currentSearchUncontrolledTransaction.transactionId);
                }}
              >
                <RemoveCircleOutlineOutlined />
              </Box>
            ),
            select: (
              <Checkbox
                checked={selectedIds.includes(Number(currentSearchUncontrolledTransaction.transactionId))}
                value={currentSearchUncontrolledTransaction.transactionId}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (currentSearchUncontrolledTransaction.transactionId) {
                    handleSelectUncontrolledTransaction(Number(event?.target?.value));
                  }
                }}
              />
            ),
            actions: <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
          }
        : {};
    });
    tableDisplayData = (sortData(
      filterData(mapToTableData, filterObject),
      sortObject
    ) as unknown) as CurrentSearchUncontrolledTransaction[];
  }

  const displayColumns = [
    {
      key: 'transactionId',
      header: (
        <SearchAndSort
          field={t('analysis:column-id')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'transactionId', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, transactionId: value });
          }}
        />
      ),
      width: 100
    },
    {
      key: 'entityName',
      header: (
        <SearchAndSort
          field={t('analysis:column-entities')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'entityName', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, entityName: value });
          }}
        />
      ),
      width: 100
    },
    { key: 'transactionType', header: t('analysis:column-transaction-type'), width: 100 },
    { key: 'propertyTransferred', header: t('transactions:label-property-transferred'), width: 100 },
    { key: 'acceptedStatus', header: t('analysis:column-source-range-accepted-status'), width: 100 },
    {
      key: 'rangeValue',
      header: showRangeValueFixFormatting
        ? getRangeValueHeader(transactionType, method, isUnitPrice, 'prefix')
        : t('analysis:column-source-range-value-percent'),
      width: 100
    },
    { key: 'remove', header: '', width: 10 },
    {
      key: 'select',
      header: (
        <Checkbox
          checked={selectedIds.length > 0 && selectedIds.length === currentSearchUncontrolledTransactions?.length}
          onChange={selectAll}
        />
      ),
      width: 10
    },
    { key: 'actions', header: '', width: 10 }
  ];

  return (
    <Paper className={classes.paperContainer}>
      <TbaUncontrolledTransactionsHeader
        title={t('analysis:current-search')}
        chips={chipsData}
        selectedIds={selectedIds}
        tabLabel={tabLabel}
        filterByStatus={filterByStatus}
        handleDeselectAll={handleDeselectAll}
        setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
        onCreateSingleTransaction={onCreateSingleTransaction}
        onUploadMultipleTransactions={onUploadMultipleTransactions}
        onBulkUpdateStatus={bulkUpdateComparability}
        onBulkDeleteFromCurrentSearch={bulkRemoveTbaUncontrolledTransactions}
        onCopyTba={onCopyTba}
      />
      {savingNewTransaction ? (
        <CenteredProgress />
      ) : currentSearchUncontrolledTransactions?.length ? (
        <Box className={classes.container}>
          <Box className={classes.tableWrapper}>
            <Table
              className={classes.scrollTable}
              data={tableDisplayData}
              columns={displayColumns}
              stickyCols={stickyCols}
            />
          </Box>
        </Box>
      ) : (
        <TBACurrentSearchNoTransSplashScreen />
      )}
    </Paper>
  );
};
