import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { TbaUncontrolledTransactionsBulkActionsCard } from './TbaUncontrolledTransactionsBulkActionsCard';
import { UncontrolledTransactionsCopyTbaModal } from './TbaUncontrolledTransactionsCopyTbaModal';
import { UncontrolledTransactionUploadModal } from './TbaUncontrolledTransactionsUploadModal';
import { DropDownButton } from '../../../components';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { selectCurrentTBA } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { title2 } from '../../../styles/typography';
import { hasEditAccess, isNotDIY } from '../../../utils';
import { CurrentSearchBulkActionsCard } from '../../CurrentSearchBulkActionsCard';
import { SummaryChips } from '../../SummaryChips';
import { TbaUncontrolledTransactionsHeaderProps } from '../TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    minHeight: '14rem',
    padding: '1.375em',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto'
  },
  headerArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& h1, & h2': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0
    }
  },
  title2,
  buttonDivider: {
    margin: '0 2rem',
    border: `1px solid ${tokens.neutral90}`,
    height: '2.625rem'
  }
}));

export const TbaUncontrolledTransactionsHeader = ({
  title,
  chips,
  selectedIds,
  tabLabel,
  filterByStatus,
  onCreateSingleTransaction,
  onUploadMultipleTransactions,
  setIsOpenImportFromRoyaltyStatModal,
  onBulkUpdateStatus,
  onBulkDeleteFromCurrentSearch,
  handleDeselectAll,
  onAddToSearch,
  onCopyTba,
  onBulkDeleteFromAllUncontrolled
}: TbaUncontrolledTransactionsHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [featureFlagIsActive] = useFeatureFlags();
  const tba = useSelector(selectCurrentTBA);

  const isCurrentSearch = useMemo(() => tabLabel && tabLabel === t('analysis:current-search'), [tabLabel, t]);
  const isAllUncontrolled = useMemo(() => tabLabel && tabLabel === t('analysis:all-uncontrolled'), [tabLabel, t]);
  const [isOpenUploadModal, setisOpenUploadModal] = useState<boolean>(false);
  const [isCopyTBAModalOpen, setIsCopyTBAModalOpen] = useState<boolean>(false);

  const showAllUncontrolledBulkActionsFF = featureFlagIsActive('tbaAllUncontrolledBulkActions');
  const showTBACopyModalButton = featureFlagIsActive('copyTbaNewUi');
  const showRoyaltyStatImportButton = featureFlagIsActive('royaltyStatImport');

  const hasTbaSelected = (selectedIds?.length ?? 0) > 0;
  const shouldCurrentSearchBulkActionsShow = isCurrentSearch && hasTbaSelected;
  const shouldAllUncontrolledBulkActionsShow = isAllUncontrolled && hasTbaSelected && showAllUncontrolledBulkActionsFF;

  const items = useMemo(() => {
    const transactionDropdownItems = [
      {
        children: t('analysis:action-create-single-transaction'),
        onClick: () => {
          onCreateSingleTransaction();
        }
      },
      {
        children: t('analysis:action-upload-multiple-transactions'),
        onClick: () => {
          setisOpenUploadModal(true);
        }
      }
    ];

    if (isCurrentSearch && showTBACopyModalButton) {
      transactionDropdownItems.push({
        children: t('analysis:action-copy-from-tba'),
        onClick: () => {
          setIsCopyTBAModalOpen(true);
        }
      });
    }

    if (
      showRoyaltyStatImportButton &&
      (tba?.transactionType.transactionTypeId === 2 || tba?.transactionType.transactionTypeId === 3) &&
      tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 1 &&
      isNotDIY()
    ) {
      transactionDropdownItems.push({
        children: t('analysis:action-import-from-royalty-stat'),
        onClick: () => {
          setIsOpenImportFromRoyaltyStatModal(true);
        }
      });
    }

    return transactionDropdownItems;
  }, [
    onCreateSingleTransaction,
    t,
    isCurrentSearch,
    showTBACopyModalButton,
    showRoyaltyStatImportButton,
    setIsOpenImportFromRoyaltyStatModal,
    tba?.transactionType.transactionTypeId,
    tba?.tbaEvaluationMethod.tbaEvaluationMethodId
  ]);

  // ToDo: Move Upload component to TbaUncontrolledTransactions.tsx
  return (
    <>
      <Box className={classes.headerArea}>
        <Typography variant="h2" className={classes.title2}>
          {title}
        </Typography>
        {!shouldCurrentSearchBulkActionsShow && !shouldAllUncontrolledBulkActionsShow && (
          <Box className={classes.headerArea}>
            <DropDownButton variant="outlined" color="primary" disabled={!hasEditAccess()} items={items}>
              <Add />
              {t('transactions:action-add-choice')}
            </DropDownButton>
            <Box component="span" className={classes.buttonDivider} />
            <SummaryChips chips={chips} clickHandler={filterByStatus} />
          </Box>
        )}
        {shouldCurrentSearchBulkActionsShow && (
          <Box className={classes.headerArea}>
            <CurrentSearchBulkActionsCard
              selectedUncontrolledTransactionIds={selectedIds!}
              unselectAll={handleDeselectAll!}
              onBulkDeleteFromCurrentSearch={onBulkDeleteFromCurrentSearch!}
              onBulkUpdateStatus={onBulkUpdateStatus!}
            />
          </Box>
        )}
        {shouldAllUncontrolledBulkActionsShow && (
          <Box className={classes.headerArea}>
            <TbaUncontrolledTransactionsBulkActionsCard
              selectedUncontrolledTransactionIds={selectedIds ?? []}
              unselectAll={handleDeselectAll!}
              onAddToSearch={onAddToSearch!}
              onBulkDeleteFromAllUncontrolled={onBulkDeleteFromAllUncontrolled!}
            />
          </Box>
        )}
      </Box>
      <UncontrolledTransactionUploadModal
        title={t(`analysis:tba-uncontrolled-transaction-upload`, { section: title })}
        description={t(`analysis:tba-uncontrolled-transaction-upload-import-description`, { section: title })}
        isOpen={isOpenUploadModal}
        setisOpenUploadModal={setisOpenUploadModal}
        source={tabLabel ?? ''}
        onUploadMultipleTransactions={onUploadMultipleTransactions}
      />
      <UncontrolledTransactionsCopyTbaModal
        title={t('analysis:tba-uncontrolled-transaction-copy-tba-modal-title')}
        isOpen={isCopyTBAModalOpen}
        setIsOpenUploadModal={setIsCopyTBAModalOpen}
        currentTba={tba}
        onCopyTba={onCopyTba}
      />
    </>
  );
};
