import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { DropDownButton, Editor, TPModal } from '../../components';
import { selectComparabilityOptions } from '../../selectors';
import tokens from '../../styles/designTokens';
import { body1, body2, buttonText2, captionOverline, title2 } from '../../styles/typography';
import { SelectedUndetermined, TextGenericColor, TextGenericColorSmall } from '../../svgs';
import { UncontrolledTransactionComparabilities } from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '3rem',
    maxWidth: '33.313rem',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${tokens.purpleLight2}`,
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  selectedButton: {
    borderRadius: '0.813rem',
    backgroundColor: tokens.purpleLight2,
    height: '1.563rem',
    width: '8.313rem',
    marginRight: '0.688rem',
    marginLeft: theme.spacing(1)
  },
  selectedText: {
    ...captionOverline,
    color: tokens.white,
    textTransform: 'uppercase',
    letterSpacing: '0.6px'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  buttons: {
    padding: '0.313rem',
    color: tokens.product100,
    border: `1px solid ${tokens.product100}`,
    marginRight: theme.spacing(1),
    '&:hover': {
      background: theme.palette.action.selected
    }
  },
  descriptionIcon: {
    height: '0.875rem',
    width: '1rem'
  },
  deselectText: {
    height: '1.75rem',
    width: '3.625rem',
    color: tokens.product100,
    ...buttonText2,
    textAlign: 'center',
    marginRight: theme.spacing(1)
  },
  cardContent: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  checkCircle: {
    color: tokens.positive70
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  header: {
    display: 'inline-flex'
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70
  },
  headerIcon: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    marginRight: '0.313rem',
    padding: '0.5rem',
    borderRadius: '25%'
  },
  title2,
  descriptionContainer: {
    paddingLeft: '0.313rem',
    paddingRight: '0.625rem'
  },
  identifier: {
    ...body2,
    color: tokens.core2
  },
  iconSize: {
    minWidth: '2.25rem'
  },
  formControl: {
    width: '100%'
  },
  formDivider: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  selectedBIBackground: {
    backgroundColor: (props: any) => (props.selectedBIBackgroundColor === true ? theme.palette.primary.light : ''),
    '& .MuiSelect-outlined': {
      borderRadius: 'none'
    },
    '& .MuiOutlinedInput-input': {
      color: (props: any) => (props.selectedBIBackgroundColor === true ? theme.palette.primary.dark : '')
    }
  },
  selectedFABackground: {
    backgroundColor: (props: any) => (props.selectedFABackgroundColor === true ? theme.palette.primary.light : ''),
    '& .MuiSelect-outlined': {
      borderRadius: 'none'
    },
    '& .MuiOutlinedInput-input': {
      color: (props: any) => (props.selectedFABackgroundColor === true ? theme.palette.primary.dark : '')
    }
  },
  selectedCABackground: {
    backgroundColor: (props: any) => (props.selectedCABackgroundColor === true ? theme.palette.primary.light : ''),
    '& .MuiSelect-outlined': {
      borderRadius: 'none'
    },
    '& .MuiOutlinedInput-input': {
      color: (props: any) => (props.selectedCABackgroundColor === true ? theme.palette.primary.dark : '')
    }
  },
  analysisTypeLabel: {
    ...body1
  },
  initMargin: {
    paddingTop: theme.spacing(1),
    width: '12.875rem'
  },
  menuLabel: {
    ...body1,
    color: theme.palette.primary.dark
  },
  applyButton: {
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light
    }
  }
}));

export interface BulkActionProp {
  selectedUncontrolledTransactionIds: number[];
  unselectAll: () => void;
  bulkUpdateComparabilityStatus: (transactionComparabilityId: number[], status: number) => Promise<void>;
  bulkUpdateSimilarities: (
    selectedUncontrolledTransactionIds: number[],
    economicAnalysisSimilarity: number,
    functionalAnalysisSimilarity: number,
    contractualTermsSimilarity: number
  ) => Promise<void>;
  bulkUpdateEvaluations: (
    selectedUncontrolledTransactionIds: number[],
    comparabilityEvaluation: string
  ) => Promise<void>;
}
const ComparabilityBulkActionCard = ({
  selectedUncontrolledTransactionIds,
  unselectAll,
  bulkUpdateComparabilityStatus,
  bulkUpdateSimilarities,
  bulkUpdateEvaluations
}: BulkActionProp) => {
  const [selectedBIBackgroundColor, setSelectedBIBackgroundColor] = useState<boolean>(false);
  const [selectedFABackgroundColor, setSelectedFABackgroundColor] = useState<boolean>(false);
  const [selectedCABackgroundColor, setSelectedCABackgroundColor] = useState<boolean>(false);

  const [openBulkDescriptionModal, setOpenBulkDescriptionModal] = useState<boolean>(false);
  const [comparabilityEvaluation, setComparabilityEvaluation] = useState('');
  const [bulkBackgroundInformation, setBulkBackgroundInformation] = useState('');
  const [bulkFunctionalAnalysis, setBulkFunctionalAnalysis] = useState('');
  const [bulkContractualAnalysis, setBulkContractualAnalysis] = useState('');

  const [applyButtonDisabled, setApplyButtonDisabled] = useState<boolean>(true);

  const comparabilityOptions = useSelector(selectComparabilityOptions) ?? [];

  const classes = useStyles({
    selectedBIBackgroundColor,
    selectedFABackgroundColor,
    selectedCABackgroundColor,
    applyButtonDisabled
  });
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultValues = {
    defaultBIselectedValue: '',
    defaultFAselectedValue: '',
    defaultCAselectedValue: ''
  };
  const formMethods = useForm();
  const { control, reset } = formMethods;

  const updateBulkBackgroundInformation = (event: any) => {
    console.log(
      '🚀 ~ file: component.tsx:136 ~ ComparabilityBulkActionCard ~ bulkBackgroundInformation',
      bulkBackgroundInformation
    );
    setBulkBackgroundInformation(event.target.value);
  };

  const updateBulkFunctionalAnalysis = (event: any) => {
    console.log(
      '🚀 ~ file: component.tsx:136 ~ ComparabilityBulkActionCard ~ bulkBackgroundInformation',
      bulkFunctionalAnalysis
    );
    setBulkFunctionalAnalysis(event.target.value);
  };

  const updateBulkContractualAnalysis = (event: any) => {
    console.log(
      '🚀 ~ file: component.tsx:136 ~ ComparabilityBulkActionCard ~ bulkBackgroundInformation',
      bulkContractualAnalysis
    );
    setBulkContractualAnalysis(event.target.value);
  };

  return (
    <>
      <Box>
        <Card variant="outlined" className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Button disabled variant="outlined" className={classes.selectedButton}>
              <Typography
                className={classes.selectedText}
              >{`${selectedUncontrolledTransactionIds.length} selected`}</Typography>
            </Button>
            <DropDownButton
              variant="outlined"
              className={classes.buttons}
              items={[
                {
                  children: (
                    <>
                      <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                      <Typography className={`${classes.dropDown}`}>
                        {t(`analysis:comparability-range-accepted`)}
                      </Typography>
                    </>
                  ),
                  onClick: () => {
                    void bulkUpdateComparabilityStatus(
                      selectedUncontrolledTransactionIds,
                      UncontrolledTransactionComparabilities.Accepted
                    );
                  }
                },
                {
                  children: (
                    <>
                      <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                      <Typography className={classes.dropDown}>{t(`analysis:comparable-range-rejected`)}</Typography>
                    </>
                  ),
                  onClick: () => {
                    void bulkUpdateComparabilityStatus(
                      selectedUncontrolledTransactionIds,
                      UncontrolledTransactionComparabilities.Rejected
                    );
                  }
                },
                {
                  children: (
                    <>
                      <SelectedUndetermined className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                      <Typography className={classes.dropDown}>
                        {t(`analysis:comparable-range-undetermined`)}
                      </Typography>
                    </>
                  ),
                  onClick: () => {
                    void bulkUpdateComparabilityStatus(
                      selectedUncontrolledTransactionIds,
                      UncontrolledTransactionComparabilities.Undetermined
                    );
                  }
                }
              ]}
            >
              {t(`analysis:tba-uncontrolled-transaction-comparability-status`)}
            </DropDownButton>
            <DropDownButton
              closeAnchor
              variant="outlined"
              className={classes.buttons}
              items={[
                {
                  children: (
                    <Typography className={`${classes.analysisTypeLabel} ${classes.initMargin}`}>
                      {t(`analysis:background-information`)}
                    </Typography>
                  )
                },
                {
                  children: (
                    <FormControl size="small" className={classes.formControl}>
                      <Controller
                        render={({ value, onChange }) => (
                          <Select
                            displayEmpty
                            variant="outlined"
                            value={value}
                            className={classes.selectedBIBackground}
                            onChange={($event) => {
                              onChange($event);
                              setSelectedBIBackgroundColor(true);
                              updateBulkBackgroundInformation($event);
                              setApplyButtonDisabled(false);
                            }}
                          >
                            <MenuItem disabled value="">
                              <div>Select</div>
                            </MenuItem>
                            {comparabilityOptions.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="defaultBIselectedValue"
                        control={control}
                        defaultValue={defaultValues.defaultBIselectedValue}
                      />
                    </FormControl>
                  )
                },
                {
                  children: (
                    <Typography className={classes.analysisTypeLabel}>{t(`analysis:functional-analysis`)}</Typography>
                  )
                },
                {
                  children: (
                    <FormControl size="small" variant="outlined" className={classes.formControl}>
                      <Controller
                        render={({ value, onChange }) => (
                          <Select
                            displayEmpty
                            value={value}
                            className={classes.selectedFABackground}
                            onChange={($event) => {
                              onChange($event);
                              setSelectedFABackgroundColor(true);
                              updateBulkFunctionalAnalysis($event);
                              setApplyButtonDisabled(false);
                            }}
                          >
                            <MenuItem disabled value="">
                              <div>Select</div>
                            </MenuItem>
                            {comparabilityOptions.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="defaultFAselectedValue"
                        control={control}
                        defaultValue={defaultValues.defaultFAselectedValue}
                      />
                    </FormControl>
                  )
                },
                {
                  children: (
                    <Typography className={classes.analysisTypeLabel}>{t(`analysis:contractual-terms`)}</Typography>
                  )
                },
                {
                  children: (
                    <FormControl
                      size="small"
                      variant="outlined"
                      className={`${classes.formControl} ${classes.formDivider}`}
                    >
                      <Controller
                        render={({ value, onChange }) => (
                          <Select
                            displayEmpty
                            value={value}
                            className={classes.selectedCABackground}
                            onChange={($event) => {
                              onChange($event);
                              setSelectedCABackgroundColor(true);
                              updateBulkContractualAnalysis($event);
                              setApplyButtonDisabled(false);
                            }}
                          >
                            <MenuItem disabled value="">
                              <div>Select</div>
                            </MenuItem>
                            {comparabilityOptions.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="defaultCAselectedValue"
                        control={control}
                        defaultValue={defaultValues.defaultCAselectedValue}
                      />
                    </FormControl>
                  )
                },
                {
                  children: (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      className={classes.applyButton}
                      disabled={applyButtonDisabled}
                      style={{ marginTop: theme.spacing(1) }}
                      onClick={() => {
                        void bulkUpdateSimilarities(
                          selectedUncontrolledTransactionIds,
                          Number(bulkBackgroundInformation),
                          Number(bulkFunctionalAnalysis),
                          Number(bulkContractualAnalysis)
                        );
                      }}
                    >
                      {t(`analysis:tba-uncontrolled-transaction-comparability-bulk-apply-selected`)}
                    </Button>
                  )
                }
              ]}
              menuProps={{ backgroundColor: tokens.white, padding: '0 0.5rem 0.5rem 0.5rem' }}
              onItemClose={() => {
                reset({
                  defaultselectedValue: ''
                });
                setSelectedBIBackgroundColor(false);
                setSelectedFABackgroundColor(false);
                setSelectedCABackgroundColor(false);
                setApplyButtonDisabled(true);
              }}
            >
              {t(`analysis:comparability`)}
            </DropDownButton>
            <Button
              variant="outlined"
              className={`${classes.buttons} ${classes.iconSize}`}
              onClick={() => {
                setOpenBulkDescriptionModal(true);
              }}
            >
              <TextGenericColorSmall className={classes.descriptionIcon} />
            </Button>
            <Button
              variant="text"
              className={classes.deselectText}
              onClick={() => {
                unselectAll();
              }}
            >
              {t(`analysis:tba-uncontrolled-transaction-comparability-deselect`)}
            </Button>
          </CardContent>
        </Card>
      </Box>
      {openBulkDescriptionModal && (
        <TPModal
          isOpen
          maxWidth="sm"
          containerStyle={classes.contentWrapper}
          title={
            <Box className={classes.header}>
              <TextGenericColor className={classes.headerIcon} />
              <Typography className={classes.title2}>
                {t('analysis:tba-uncontrolled-transaction-comparability-description')}
              </Typography>
            </Box>
          }
          actions={[
            {
              name: t('action-cancel'),
              variant: 'outlined',
              color: 'primary',
              handler: () => {
                setOpenBulkDescriptionModal(false);
                setComparabilityEvaluation('');
              }
            },
            {
              name: t('action-apply-changes'),
              color: 'primary',
              variant: 'contained',
              handler: () => {
                void bulkUpdateEvaluations(selectedUncontrolledTransactionIds, comparabilityEvaluation);
                setOpenBulkDescriptionModal(false);
                setComparabilityEvaluation('');
              }
            }
          ]}
          onClose={() => {
            setOpenBulkDescriptionModal(false);
            setComparabilityEvaluation('');
          }}
        >
          <Box className={classes.descriptionContainer}>
            <Typography className={classes.identifier}>
              {`${selectedUncontrolledTransactionIds.length} Transactions Selected`}
            </Typography>
            <Box>
              <Editor
                theme="TransferPricing"
                id="uncontrolledcomparibilitydescription"
                init={{ height: '250' }}
                value={comparabilityEvaluation}
                onEditorChange={(content) => {
                  setComparabilityEvaluation(content ?? '');
                }}
              />
            </Box>
          </Box>
        </TPModal>
      )}
    </>
  );
};

export default ComparabilityBulkActionCard;
