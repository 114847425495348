import { ReactNode } from 'react';
import { TransactionBasedAnalysis } from '../../models';
import { SingleChipProps, SummaryChipsProps } from '../SummaryChips';
import {
  StatusTypes,
  TbaUncontrolledTransactionComparability
} from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

export interface ComparabilityTabProps {
  selectedTba: TransactionBasedAnalysis | null;
  updateComparabilityApiCall: (transactionComparabilityId: number, status: number, tbaId: number) => void;
  updateUncontrolledTransactionComparibility: (payload: ComparabileAnalysisTableTransactions) => void;
  bulkUpdateComparabilityApiCall: (uncontrolledTransactionIds: number[], status: number, tbaId: number) => void;
  bulkUpdateSimilaritiesApiCall: ({
    tbaId,
    uncontrolledTransactionIds,
    economicAnalysisSimilarity,
    functionalAnalysisSimilarity,
    contractualTermsSimilarity
  }: BulkUpdateSimilaritiesApiCallParams) => void;
  bulkUpdateEvaluationsApiCall: ({
    tbaId,
    uncontrolledTransactionIds,
    comparabilityEvaluation
  }: BulkUpdateEvaluationsApiCallParams) => void;
}

export interface TbaComparableAnalysisProps {
  selectedTba: TransactionBasedAnalysis | null;
  tabs: Array<{
    key: string;
    label: string;
    disabled: boolean;
    content: ReactNode;
  }>;
}

export interface ComparabileAnalysisTableTransactions {
  backgroundInformation?: string;
  contractualTerms?: string;
  functionalAnalysis?: string;
  entityName?: string;
  identifier?: string;
  status: number;
  transactionId?: number;
  transactionComparabilityId?: number;
  comparabilityEvaluation: string;
  contractualTermsSimilarity?: number;
  economicAnalysisSimilarity?: number;
  functionalAnalysisSimilarity?: number;
  tba?: TransactionBasedAnalysis | null;
}

export interface ComparableAnalysisTableProps {
  uncontrolledTransactions: TbaUncontrolledTransactionComparability[];
  updateComparability: (transactionComparabilityId: number, status: number) => Promise<void>;
  onApplyDescription: (selectedTransactionToUpdate: ComparabileAnalysisTableTransactions) => void;
  selectedUncontrolledTransactionIds: number[];
  selectUncontrolledTransaction: (index: number, value: number) => void;
  selectAllUncontrolledTransactions: (transactionsIds: number[]) => void;
}

export interface ComparableAnalysisHeaderProps extends SummaryChipsProps {
  chips: SingleChipProps[];
  filterByStatus: (status: StatusTypes) => void;
  selectedUncontrolledTransactionIds: number[];
  unselectAll: () => void;
  bulkUpdateComparabilityStatus: (selectedUncontrolledTransactionIds: number[], status: number) => Promise<void>;
  bulkUpdateSimilarities: (
    selectedUncontrolledTransactionIds: number[],
    economicAnalysisSimilarity: number,
    functionalAnalysisSimilarity: number,
    contractualTermsSimilarity: number
  ) => Promise<void>;
  bulkUpdateEvaluations: (
    selectedUncontrolledTransactionIds: number[],
    comparabilityEvaluation: string
  ) => Promise<void>;
}

export interface ComparabilityOption {
  id: number;
  name: string;
}

export const statusIdMap = {
  total: 0,
  rejected: 1,
  accepted: 2,
  remaining: 3,
  acceptedAndReviewed: 4
};
export interface BulkUpdateSimilaritiesApiCallParams {
  tbaId: number;
  uncontrolledTransactionIds: number[];
  economicAnalysisSimilarity: number;
  functionalAnalysisSimilarity: number;
  contractualTermsSimilarity: number;
}

export interface BulkUpdateEvaluationsApiCallParams {
  tbaId: number;
  uncontrolledTransactionIds: number[];
  comparabilityEvaluation: string;
}
