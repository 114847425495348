import {
  TransactionBasedAnalysis,
  Container,
  Entity,
  PartyRole,
  TransactionType,
  Transaction,
  Currency
} from '../../models';
import {
  CopyTBAParameters,
  TbaRangeType,
  UploadMultipleTransactionsParams
} from '../../redux/transactionBasedAnalyses';
import { FinancialInfoFieldValues } from '../NewAnalysisModal/CreateTBAModal.proptypes';
import { SingleChipProps } from '../SummaryChips';

export interface TbaUncontrolledTransactionsProps {
  tba: TransactionBasedAnalysis;
  tbaParameters: TbaParameters[];
  tbaRangeTypes?: TbaRangeType[];
  currentSearchUncontrolledTransactions?: CurrentSearchUncontrolledTransaction[];
  allUncontrolledTransactions: UncontrolledTransaction[];
  onCreateSingleTransaction: (source?: CreateTransactionModalSource) => void;
  onEditSingleTransaction: (transactionId: number) => void;
  onCopyTba: (data: CopyTBAParameters) => void;
  onUploadMultipleTransactions: (data: UploadMultipleTransactionsParams) => void;
  setIsOpenImportFromRoyaltyStatModal: (isOpen: boolean) => void;
  savingNewTransaction: boolean;
  tabLabel?: string;
  isUnitPrice?: boolean;
}

export interface TbaUncontrolledTransSearchTableProps {
  tba: TransactionBasedAnalysis;
  tbaRangeTypes?: TbaRangeType[];
  currentSearchUncontrolledTransactions?: CurrentSearchUncontrolledTransaction[];
  onCreateSingleTransaction: (source?: CreateTransactionModalSource) => void;
  onEditSingleTransaction: (transactionId: number, source: string) => void;
  onUploadMultipleTransactions: (data: UploadMultipleTransactionsParams) => void;
  setIsOpenImportFromRoyaltyStatModal: (isOpen: boolean) => void;
  onCopyTba: (data: CopyTBAParameters) => void;
  savingNewTransaction: boolean;
  tabLabel?: string;
  isUnitPrice?: boolean;
}

export interface TbaUncontrolledTransShowAllProps {
  title: string;
  allUncontrolledTransactions: UncontrolledTransaction[];
  tba: TransactionBasedAnalysis;
}

export interface CurrentSearchUncontrolledTransaction extends UncontrolledTransaction {
  parameterValue?: number;
  status?: number;
  transactionComparabilityId?: number;
  comparabilityEvaluation: string;
}
export type StatusTypes = 'total' | 'accepted' | 'rejected' | 'remaining' | 'acceptedAndReviewed';
export type CreateTransactionModalSource = 'all-uncontrolled' | 'current-search' | '';
export interface TbaUncontrolledTransactionsHeaderProps {
  title: string;
  chips: SingleChipProps[];
  selectedIds?: number[];
  tabLabel?: string;
  selectedUncontrolledTransactionIds?: number[];
  onCreateSingleTransaction: (source?: CreateTransactionModalSource) => void;
  onCopyTba: (data: CopyTBAParameters) => void;
  onUploadMultipleTransactions: (data: UploadMultipleTransactionsParams) => void;
  setIsOpenImportFromRoyaltyStatModal: (isOpen: boolean) => void;
  onBulkUpdateStatus?: (ids: number[], status: number) => void;
  onBulkDeleteFromCurrentSearch?: (ids: number[]) => void;
  handleDeselectAll?: () => void;
  filterByStatus?: (status: StatusTypes) => void;
  onAddToSearch?: (ids: number[]) => void;
  onBulkDeleteFromAllUncontrolled?: (ids: number[]) => void;
}

export interface TbaUncontrolledTransactionsSplashScreenProps {
  onContinue: () => void;
}

export interface TbaUncontrolledTransactionComparability {
  transactionComparabilityId: number;
  economicAnalysisSimilarity: number;
  functionalAnalysisSimilarity: number;
  contractualTermsSimilarity: number;
  comparabilityEvaluation: string;
  status: number;
  justifyAction: string;
  uncontrolledTransaction: UncontrolledTransaction;
  tbaRangeComponent: TbaRangeComponent;
  tba?: TransactionBasedAnalysis | null;
}

export interface TbaRangeComponent {
  tbaRangeComponentId: number;
  finalRangeValue: number;
}

export interface TbaParameter {
  tbaParameterId: number;
  type: string;
  description: FinancialInfoFieldValues;
  calculated: boolean;
  optional: boolean;
}

export interface UncontrolledTransactionParameters {
  parameterValue: number;
  tbaUncontrolledParameterId: number;
  tba: {
    tbaId: number;
    name: string;
    exclude: boolean;
    isSourceComplete: boolean;
    isDestinationComplete: boolean;
    description: string;
  };
  tbaParameter: TbaParameter;
  uncontrolledTransaction: {
    transactionId: number;
    identifier: string;
    propertyTransferred: string;
    transferDate: string;
    units: number;
    value: number;
    description: string;
    valueEnteredIn: number;
  };
}

export interface UncontrolledTransaction {
  container: Container;
  description: string;
  identifier: string;
  partyRole: PartyRole;
  propertyTransferred: string;
  transactionId: number;
  transactionType: TransactionType;
  transferDate: string;
  ucDestinationLegalEntity: Entity;
  ucSourceLegalEntity: Entity;
  sourceLegalEntity?: Entity;
  destinationLegalEntity?: Entity;
  units: number;
  value: number;
  valueEnteredIn: number;
  parameterValue?: number;
  comparabilityEvaluation: string;
}

interface CreateUncontrolledTransactionEntity {
  domicile?: Record<string, unknown>;
  container?: Record<string, unknown>;
  name?: string;
}

export interface CreateUncontrolledTransaction {
  container: Container;
  description: string;
  identifier: string;
  partyRole: PartyRole;
  propertyTransferred: string;
  transactionType: TransactionType;
  transferDate: string;
  ucDestinationLegalEntity?: CreateUncontrolledTransactionEntity;
  ucSourceLegalEntity?: CreateUncontrolledTransactionEntity;
  sourceLegalEntity?: CreateUncontrolledTransactionEntity;
  destinationLegalEntity?: CreateUncontrolledTransactionEntity;
  units?: number;
  value: number;
  valueEnteredIn: number;
}

export interface EditUncontrolledTransaction {
  container: Container;
  description: string;
  identifier: string;
  partyRole: PartyRole;
  propertyTransferred: string;
  transactionId: number;
  transactionType: TransactionType;
  transferDate: string;
  ucDestinationLegalEntity?: CreateUncontrolledTransactionEntity;
  ucSourceLegalEntity?: CreateUncontrolledTransactionEntity;
  sourceLegalEntity?: CreateUncontrolledTransactionEntity;
  destinationLegalEntity?: CreateUncontrolledTransactionEntity;
  units?: number;
  value: number;
  valueEnteredIn: number;
}

export interface UncontrolledTransactionTableData {
  transactionId: number;
  entityName: string;
  transactionType: string;
  propertyTransferred: string;
  rangeValue: string;
  acceptedStatus: string;
}

export interface IconMenuItem {
  text: string;
  handleOnClick: () => void;
}

export interface TBAUncontrolledTransactionTableProps {
  uncontrolledTransactions: UncontrolledTransaction[];
}

export const UncontrolledTransactionComparabilities = {
  Accepted: 2,
  Rejected: 1,
  Undetermined: 0
};

export interface TbaParameters {
  parameterValue: number;
  tbaControlledParameterId: number;
  tba: { tbaId: number };
  tbaParameter: TbaParameter;
}

export interface TbaUncontrolledParametersPayload {
  parameterValue: number;
  tba: {
    tbaId: number;
  };
  tbaParameter: {
    description?: string;
    type?: string;
    tbaParameterId: number;
  };
  uncontrolledTransaction: {
    transactionId: number;
  };
}

export interface UpdateTbaUncontrolledParameter {
  parameterValue: number;
  tbaUncontrolledParameterId?: number;
  tbaParameterId: number;
}

export const StatisticalMethodIds = {
  NoSelected: 0,
  FullRange: 1,
  InterquartileRangeIRS: 2,
  InterquartileRange: 3
};

export interface CreateNewUncontrolledTransactionModalProps {
  tba: TransactionBasedAnalysis;
  entities: Entity[];
  transactions: Transaction[] | null;
  container: Container | undefined;
  partyRoles: PartyRole[] | null;
  source: CreateTransactionModalSource;
  onClose: () => void;
  submitNewUncontrolledTransaction: (data: any) => void;
  savingNewTransaction: boolean;
  upeCurrency: Currency;
}

export interface EditUncontrolledTransactionModalProps {
  tba: TransactionBasedAnalysis;
  transactionToEdit: UncontrolledTransaction;
  entities: Entity[];
  transactions: Transaction[] | null;
  transactionToEditFiInfo: UncontrolledTransactionParameters[];
  container: Container | undefined;
  partyRoles: PartyRole[] | null;
  onClose: () => void;
  submitEditedUncontrolledTransaction: (data: any) => void;
  savingEditedTransaction: boolean;
  upeCurrency: Currency;
  source: CreateTransactionModalSource;
}
