/* eslint-disable no-negated-condition */
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CenteredProgress, SearchAndSort, SortOrder } from '../../../components';
import { SortControl } from '../../../components/SortControl';
import { RangeColumns, RangeComparables } from '../../../models';
import { selectWorkingContainer } from '../../../selectors';
import { filterData, sortData } from '../../../services/filtering';
import tokens from '../../../styles/designTokens';
import { body2, body4 } from '../../../styles/typography';
import { getAppConfig } from '../../../utils';
import { Table } from '../../Table';
import { TableColumnDef, StickyColsDefinition } from '../../Table/Table.proptype';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  scrollTable: {
    width: '100%'
  },
  headingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 1rem'
  },
  pinnedBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0rem 0rem 0rem 0.313rem'
  },
  unpinnedBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0rem 0.5rem'
  },
  circle: {
    border: `0.06rem solid ${tokens.supportColorA100}`,
    height: '0.75rem',
    width: '0.75rem',
    backgroundColor: tokens.supportColorA20,
    borderRadius: '50%',
    justifyContent: 'center'
  },
  headingTitle: {
    ...body4,
    margin: 'auto 0'
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  emptyMessage: {
    ...body2,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2em'
  }
}));

interface CompanyTableProps {
  comparables?: RangeComparables | undefined;
  companies: Array<Record<string, HTMLElement | number>>;
  showPinnedCompanies?: boolean;
  columns: RangeColumns[];
  chips: any;
  selectedCompanies?: any[];
  bulkPanel?: any;
  filteringBulkRejection?: string;
  onRowClick?: (event: MouseEvent, index: number, data: any) => void;
  setSortedVisibleData?: (data: any[]) => void;
}

const mergeCompLists = (
  compListOne: Array<Record<string, HTMLElement | number | string>>,
  compListTwo: Array<Record<string, string | boolean>>
) =>
  compListOne.map((comparable: Record<string, any>) => ({
    ...compListTwo.find(
      (company: Record<string, string | boolean>) =>
        company.companyName === comparable.companyName.props.title && company
    ),
    ...comparable
  }));

export const CompanyTable = (props: CompanyTableProps) => {
  const {
    comparables,
    companies,
    columns,
    chips,
    selectedCompanies,
    bulkPanel,
    filteringBulkRejection,
    onRowClick,
    setSortedVisibleData,
    showPinnedCompanies = true
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};
  const { xbsEnvironmentShort } = getAppConfig();
  const flags = useFlags();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;
  const comparablesValues = useMemo(() => (comparables?.data !== undefined ? comparables.data : []), [comparables]);

  const showPinnedComps =
    showPinnedCompanies &&
    flags.priorYearCompsYear2 &&
    (flags.priorYearCompsYear2 === true ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-*`));

  const pinnedAndNonPinnedComps = useMemo(() => {
    return comparablesValues.map((comparable: Record<string, any>) => {
      return {
        companyName: comparable.companyName,
        pinned: comparable.isPinnedCompany
      };
    });
  }, [comparablesValues]);

  const mergedComparableData: Array<Record<string, HTMLElement | number | string | boolean>> = mergeCompLists(
    companies,
    pinnedAndNonPinnedComps
  );

  const displayColumns = columns
    ? columns.map((clmn) => {
        let displayClmn: TableColumnDef;

        if (clmn.key === 'companyName') {
          stickyCols[clmn.key] = {
            side: 'left',
            position: 1
          };

          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field={t('analysis:search-companies')}
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ companyName: value });
                }}
              />
            ),
            width: '18em'
          };
        } else if (clmn.key === 'pinned') {
          stickyCols[clmn.key] = {
            side: 'left',
            position: 0
          };
          displayClmn = { key: clmn.key, header: clmn.label };
        } else if (clmn.key === 'average') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 0
          };

          displayClmn = {
            key: clmn.key,
            header: (
              <SortControl
                field={`${clmn.label} (%)`}
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
              />
            ),
            align: 'right',
            width: '8em'
          };
        } else if (clmn.key === 'rejectionReason') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 1
          };

          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field="Search Rejection"
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ rejectionReason: value });
                }}
              />
            ),
            width: '18em'
          };
        } else if (clmn.key === 'rejectionStatus') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 2
          };

          displayClmn = { key: clmn.key, header: clmn.label, width: '4em' };
        } else if (clmn.key === 'rejectionType') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 1
          };

          displayClmn = {
            key: clmn.key,
            header: clmn.label
          };
        } else if (clmn.key === 'checkbox') {
          stickyCols[clmn.key] = {
            side: 'right',
            position: 3
          };
          displayClmn = { key: clmn.key, header: clmn.label, width: '3em' };
        } else if (clmn.key === 'country') {
          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field="Country"
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ country: value });
                }}
              />
            ),
            width: '12em'
          };
        } else if (clmn.key === 'sic') {
          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field="SIC"
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ sic: value });
                }}
              />
            ),
            width: '12em'
          };
        } else if (clmn.key === 'businessDescription') {
          displayClmn = {
            key: clmn.key,
            header: (
              <SearchAndSort
                field="Description"
                onSortClicked={(sortOrder) => {
                  setSort({ sortBy: clmn.key, sortOrder });
                }}
                onSearchChange={(value) => {
                  setFilter({ businessDescription: value });
                }}
              />
            ),
            width: '12em'
          };
        } else {
          displayClmn = { key: clmn.key, header: clmn.label, width: '5em' };
        }

        return displayClmn;
      })
    : [];

  const getDefaultMessage = () => {
    if (filteringBulkRejection) {
      return `${t(`analysis:no-companies-rejected`)} for ${filteringBulkRejection}`;
    }

    return t('analysis:no-companies-rejected-bulk');
  };

  const formattedData = mergedComparableData.map((company) => {
    const data: any = {};
    for (const column of displayColumns) {
      if (column.key === 'pinned') {
        data[column.key] = company.pinned ? (
          <Tooltip arrow title="Previously Accepted Comparable">
            <Box className={classes.pinnedBox}>
              <Box className={classes.circle} />
            </Box>
          </Tooltip>
        ) : (
          <Box className={classes.unpinnedBox} />
        );
      } else {
        data[column.key] = company[column.key];
      }
    }

    if (company.className) {
      data.className = company.className;
    }

    if (company.sourceId) {
      data.sourceId = company.sourceId;
    }

    return data;
  });

  const sortByAverages = (companies: any[]) => {
    const sortable: any[] = [];
    const blanks: any = [];
    companies.forEach((company) => {
      if (company.average) {
        sortable.push(company);
      } else {
        blanks.push(company);
      }
    });

    const sortableSorted: any = sortData(filterData(sortable, filterObject), sortObject);
    const blanksSortingObject: { sortBy: string; sortOrder: SortOrder } = { sortBy: 'companyName', sortOrder: 'asc' };
    const blanksSorted: any = sortData(filterData(blanks, filterObject), blanksSortingObject);

    return [...sortableSorted, ...blanksSorted];
  };

  const sortedData = useMemo(() => {
    if (sortObject.sortBy === 'average') {
      return sortByAverages(formattedData);
    }

    return sortData(filterData(formattedData, filterObject), sortObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, filterObject, sortObject]);

  const data = useMemo(() => {
    if (sortObject.sortBy === 'average') {
      return sortByAverages(companies);
    }

    return sortData(filterData(companies, filterObject), sortObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, filterObject, sortObject]);

  useEffect(() => {
    setSortedVisibleData?.(sortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject]);

  return (
    <div className={classes.container}>
      <div className={classes.headingRow}>
        <span className={classes.headingTitle}>{t('analysis:comparable-companies')}</span>
        {(selectedCompanies?.length ?? 0) > 0 ? bulkPanel : chips}
      </div>
      {!comparables ? (
        <CenteredProgress />
      ) : companies.length > 0 ? (
        <div className={classes.tableWrapper}>
          <Table
            className={classes.scrollTable}
            data={showPinnedComps ? sortedData : data}
            columns={displayColumns}
            stickyCols={stickyCols}
            columnSpecificStyles={{
              rejectionType: {
                maxWidth: '7.5rem',
                width: '7.5rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              },
              pinned: {
                padding: '0rem 0rem'
              },
              checkbox: {
                padding: '0rem 0.688rem'
              }
            }}
            onRowClick={onRowClick}
          />
        </div>
      ) : (
        <Typography className={classes.emptyMessage}>{getDefaultMessage()}</Typography>
      )}
    </div>
  );
};
